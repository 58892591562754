import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserPermissions } from 'src/environments/environment-base';
import { MsalConfigGuard, PermissionGuard, RoleGuard, UnauthenticatedGuard } from 'wre-authlib';
import { PageNotFoundComponent, UnauthorizedComponent } from 'wre-core-lib';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DatasetUploadComponent } from './components/dataset/dataset.component';
import { HomeComponent } from './home/home.component';
import { ShellComponent } from './shell/shell.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path: 'login',
    component: MsalRedirectComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: '',
    loadChildren: () => import('./user-type-selection/user-type-selection.module').then(m => m.UserTypeSelectionModule),
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [MsalGuard, PermissionGuard, MsalConfigGuard],
    data: {
      permissions: [UserPermissions.useApp],
      redirectUrl: 'unauthorized',
      toolName: 'eNTAIL'
    },

    children: [
      {
        path: 'unauthorizedaccess', component: UnauthorizedComponent,
        data: { hideRequestAccessButton: true }
      },
      { path: 'dashboard', pathMatch: 'full', redirectTo: 'dashboard/dataset-upload' },
      {
        path: 'dashboard', component: HomeComponent,
        canActivate: [PermissionGuard],
        data: {
          toolName: 'eNTAIL',
          permissions: [UserPermissions.runSimulations]
        },
        children: [
          {
            path: 'dataset-upload', component: DatasetUploadComponent
          },
          {
            path: 'configuration', component: ConfigurationComponent
          }
        ]
      },
      { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
