import { environmentBase } from './environment-base';
// TODO - Mena update ajg values from wtw
export const protectedResourceMapping = new Map(
  [
    ...environmentBase.protectedResourceMap,
    ['https://entailapiqa.gallagherre.com/api/modelling/entail/', ['api://d3196a00-f74b-4ea2-a87f-a52d87073248/GallagherRe-Entail-Api-Test']],
    ['https://gatewayqa.gallagherre.com/gatewayapi/api/', ['api://806adfde-2df8-4892-af1a-7765460d4baf/accessuser']],
    ['https://gatewayqa.gallagherre.com/timesurveyapi/api', ['api://806adfde-2df8-4892-af1a-7765460d4baf/accessuser']],
    ['https://authorizationapiqa.gallagherre.com/api/', ['api://5d7940cb-fedd-4c54-bd59-22cb9c167668/user_impersonation']]
  ]);

export const b2cProtectedResourceMap = new Map(
  [
    ['https://entailapiqa.gallagherre.com/api/modelling/entail/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
    ['https://gatewayqa.gallagherre.com/gatewayapi/api/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
    ['https://gatewayqa.gallagherre.com/timesurveyapi/api', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']],
    ['https://authorizationapiqa.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']]
  ]);
  
export const environment = {
  ...environmentBase,
  environment: 'Qa',
  production: false,
  clientId: '87c80526-9d5c-4008-80e8-98dce0da3eec',
  googleTagManagerIframeSrc:
    'https://www.googletagmanager.com/ns.html?id=GTM-NL9RW5Z&gtm_auth=zD3MnTGNY23UjBoCj4uSOQ&gtm_preview=env-3&gtm_cookies_win=x',
  gtmScriptRelativePath: '../../assets/js/googleTagManager.js',
  redirectUri: 'https://entailqa.gallagherre.com/login',
  postLogoutRedirectUri: 'https://entailqa.gallagherre.com/', // end "/" required
  appInsightsIntrumentationKey: '088e03f6-f131-480b-abd6-32d1fc136cf7',
  timesurveyApiURI: 'https://gatewayqa.gallagherre.com/timesurveyapi/api',
  gatewayUrl: 'https://gatewayqa.gallagherre.com/',
  gatewayApiUrl: 'https://gatewayqa.gallagherre.com/gatewayapi/api/',
  searchExperienceUrl: 'http://qa.wresearch.willistowerswatson.com', // TODO: maybe not needed?
  authorisationUrl: 'https://authorizationapiqa.gallagherre.com/api/',
  entailRApiUrl: 'https://entailapiqa.gallagherre.com/api/modelling/entail/',

  authority: environmentBase.authority,
  domain_hint: environmentBase.domain_hint,
  rolesConfig: environmentBase.rolesConfig,
  applicationName: environmentBase.applicationName,
  protectedResourceMap: protectedResourceMapping,
  intranetUri: environmentBase.intranetUri,
  hideNavbarSearchExperienceForPathNames: environmentBase.hideNavbarSearchExperienceForPathNames,
  rolesCacheSeconds: environmentBase.rolesCacheSeconds,
  authenticationCacheSeconds: environmentBase.authenticationCacheSeconds,
  adminGroups: environmentBase.adminGroups,
  b2cProtectedResourceMap: b2cProtectedResourceMap,
  b2cClientId: 'a3c4f6c0-d14a-4a13-9aad-054c127a5364'
};
